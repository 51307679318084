import { BLOCKS, MARKS, INLINES, Document } from "@contentful/rich-text-types";
import { documentToHtmlString, Options } from "@contentful/rich-text-html-renderer";
import { html, TemplateResult } from "lit";
import { unsafeHTML } from "lit-html/directives/unsafe-html.js";

// Options for rendering Contentful rich text
const options: Options = {
	renderMark: {
		[MARKS.BOLD]: (text: string) => `<strong>${text}</strong>`,
		[MARKS.ITALIC]: (text: string) => `<em>${text}</em>`,
		[MARKS.UNDERLINE]: (text: string) => `<u>${text}</u>`,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, next) => `<contentful-paragraph>${next(node.content)}</contentful-paragraph>`,
		[BLOCKS.HEADING_1]: (node, next) =>
			`<contentful-heading heading="${BLOCKS.HEADING_1}">${next(node.content)}</contentful-heading>`,
		[BLOCKS.HEADING_2]: (node, next) =>
			`<contentful-heading heading="${BLOCKS.HEADING_2}">${next(node.content)}</contentful-heading>`,
		[BLOCKS.HEADING_3]: (node, next) =>
			`<contentful-heading heading="${BLOCKS.HEADING_3}">${next(node.content)}</contentful-heading>`,
		[BLOCKS.HEADING_4]: (node, next) =>
			`<contentful-heading heading="${BLOCKS.HEADING_4}">${next(node.content)}</contentful-heading>`,
		[BLOCKS.HEADING_5]: (node, next) =>
			`<contentful-heading heading="${BLOCKS.HEADING_5}">${next(node.content)}</contentful-heading>`,
		[BLOCKS.HEADING_6]: (node, next) =>
			`<contentful-heading heading="${BLOCKS.HEADING_6}">${next(node.content)}</contentful-heading>`,
		[BLOCKS.UL_LIST]: (node, next) => `<ul>${next(node.content)}</ul>`,
		[BLOCKS.OL_LIST]: (node, next) => `<ol>${next(node.content)}</ol>`,
		[BLOCKS.LIST_ITEM]: (node, next) => `<li>${next(node.content)}</li>`,
		[BLOCKS.QUOTE]: (node, next) => `<blockquote>${next(node.content)}</blockquote>`,
		[BLOCKS.HR]: () => `<hr />`,
		[INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}">${next(node.content)}</a>`,
	},
};

// Function to render rich text
export function renderRichText(document: Document): TemplateResult {
	const htmlString = documentToHtmlString(document, options);
	return html`${unsafeHTML(htmlString)}`;
}
