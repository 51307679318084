export function isMobileSize() {
	return window.innerWidth < 768;
}

export type Platform = "android" | "ios" | "web-desktop" | "web-mobile";
export type CurrentPlatform = {
	isMobile: boolean;
	platform: Platform;
};
export function detectPlatform(): Platform {
	// @ts-expect-error Not part of global setup
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	// Detect mobile platforms
	if (/android/i.test(userAgent)) {
		return "android";
	}
	// @ts-expect-error Not part of global setup
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return "ios";
	}

	// Detect web mobile or desktop based on screen size
	if (isMobileSize()) {
		return "web-mobile";
	}

	return "web-desktop";
}

export function getCurrentPlatform(element: HTMLElement, callback: ({ isMobile, platform }: CurrentPlatform) => void) {
	const mediaQueryList = window.matchMedia("(max-width: 768px)");

	const updateMatches = () => {
		const updatedPlatform = detectPlatform();

		callback({
			isMobile: updatedPlatform !== "web-desktop",
			platform: updatedPlatform,
		});
	};

	const platform = detectPlatform();
	callback({
		isMobile: platform !== "web-desktop",
		platform,
	});

	// Add the listener for changes in media query
	mediaQueryList.addEventListener("change", updateMatches);

	// Clean up function to remove the listener
	const cleanup = () => {
		mediaQueryList.removeEventListener("change", updateMatches);
	};

	// Attach the cleanup function to the element's lifecycle
	element.addEventListener("disconnectedCallback", cleanup);

	// Return the cleanup for manual use if needed
	return cleanup;
}
