import { LitElement, html, css } from "lit";
import { ContentfulLivePreview } from "@contentful/live-preview";
import { ContentMarketingEntry } from "../../../types/types-contentful.js";
import { PodletMetaData } from "../../../types/types-drEdition.js";
import { getPodletMetaData } from "../../lit-util.js";

class ContentfulPreviewWrapper extends LitElement {
	declare creativeId: string;
	declare entryId: string;
	declare contentModel: ContentMarketingEntry;
	declare metaData: PodletMetaData;
	declare containerWidth: number;

	static properties = {
		creativeId: { type: String },
		entryId: { type: String },
		contentModel: { type: Object },
		containerWidth: { type: Number },
	};

	static styles = css`
		.preview-container {
			margin: 8px auto;
			padding: 8px;
		}
		.preview-mock {
			height: 100px;
			width: 100%;
			background-color: #f5f5f5;
			border: 1px solid #333;
			margin: 20px 0;
			border-radius: 8px;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0 1px 6px #0000001f;
		}
	`;

	constructor() {
		super();
		this.creativeId = "";
		this.entryId = "";
		this.contentModel = {} as ContentMarketingEntry;
		this.metaData = getPodletMetaData();
		this.containerWidth = 610;
	}

	connectedCallback() {
		super.connectedCallback();
		this.initializeLivePreview();
	}

	initializeLivePreview() {
		ContentfulLivePreview.init({
			locale: this.metaData.locale || this.contentModel.sys.locale || "en-US",
			debugMode: true,
			enableLiveUpdates: true,
			enableInspectorMode: true,
		});

		ContentfulLivePreview.subscribe({
			data: this.contentModel,
			callback: (updatedData) => {
				this.contentModel = updatedData as ContentMarketingEntry;
				this.requestUpdate();
			},
		});
	}
	_onInput(event: Event) {
		const target = event.target as HTMLInputElement;
		this.containerWidth = Number(target.value);
	}
	render() {
		const { creativeId, entryId, contentModel } = this;
		return html`
			<div class="p-8">
				<span class="m-0">Max Container Width: ${this.containerWidth}px</span>
				<br />
				<input type="range" min="300" max="700" .value="${this.containerWidth}" @input="${this._onInput}" />
			</div>
			<div class="preview-container" style="max-width: ${this.containerWidth}px">
				<div class="preview-mock px-8">Classified Ad</div>
				<div class="px-16">
					<contentful-sponsor-header
						entryId="${entryId}"
						sponsorName="${contentModel.fields.advertiserName}"
						.logoImageData="${contentModel.fields.advertiserLogo.fields}"
					></contentful-sponsor-header>
					<contentful-teaser-wrapper
						creativeId="${creativeId}"
						entryId="${entryId}"
						.contentModel="${contentModel}"
					></contentful-teaser-wrapper>
				</div>
				<div class="preview-mock px-8">Classified Ad</div>
			</div>
		`;
	}
}

customElements.define("contentful-preview-wrapper", ContentfulPreviewWrapper);
