// LitElement that is a paragraph block
import { css, html } from "lit";
import WarpElement from "@warp-ds/elements-core";
import { getPodletMetaData, setupCustomElement } from "../../lit-util.js";
import { PodletMetaData } from "../../../types/types-drEdition.js";
import { AssetFields } from "contentful/dist/types/types/asset.js";
import { CurrentPlatform, getCurrentPlatform } from "../utils/getCurrentPlatform.js";

const NAME = "contentful-sponsor-header";

export class ContentfulSponsorHeader extends WarpElement {
	declare entryId: string;
	declare sponsorName: string;
	declare logoImageData: AssetFields;
	declare metaData: PodletMetaData;
	declare currentPlatform: CurrentPlatform;

	static properties = {
		entryId: { type: String },
		sponsorName: { type: String },
		logoImageData: { type: Object },
	};

	constructor() {
		super();
		this.entryId = "";
		this.sponsorName = "";
		this.logoImageData = {} as AssetFields;
		this.metaData = getPodletMetaData();

		this.currentPlatform = {} as CurrentPlatform;
		getCurrentPlatform(this, (currentPlatform) => {
			this.currentPlatform = currentPlatform;
			this.requestUpdate();
		});
	}

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			:host {
				display: block;
			}

			.sponsor-container {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;
			}

			.sponsor-label {
				flex: 1;
				font-size: var(--w-font-size-m);
				white-space: nowrap;
			}

			.sponsor-logo {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 35%;
			}

			@media (max-width: 468px) {
				.sponsor-label {
					font-size: var(--w-font-size-s);
				}

				.sponsor-logo {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					width: 45%;
				}
			}

			.sponsor-container.dark-mode {
				color: white;
			}

			.sponsor-container.dark-mode * {
				color: white;
			}

			.sponsor-container.dark-mode img {
				background: white;
				padding: 0.2rem;
			}
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static};
		`,
		...WarpElement.styles,
	] as unknown as never;

	getContainerHeight() {
		if (this.metaData.isWebView && this.currentPlatform.platform === "ios") return "height: 7rem;";
		return "height: 6rem;";
	}

	render() {
		return html`<div
			style="${this.getContainerHeight()}"
			id="sponsor-header"
			class="sponsor-container relative flex items-center justify-between p-8 
				${this.metaData.darkMode ? "dark-mode" : ""}"
		>
			<div class="sponsor-label block ml-3 text-regular font-bold">
				${this.i18n.t({
					id: "common.sponsorLabel",
					message: "Annonsørinnhold",
				})}
			</div>
			<div class="sponsor-logo ${this.metaData.darkMode ? "s-bg" : ""} ${this.metaData.landscapeMode ? "s-bg" : ""}">
				<contentful-image
					entryId="${this.entryId}"
					fieldId="logoImageData"
					imageStyle="object-fit: contain; object-position: right; width: auto; max-height: 4.6rem;"
					width="auto"
					.imageData="${this.logoImageData}"
				></contentful-image>
			</div>
		</div>`;
	}
}

setupCustomElement(NAME, ContentfulSponsorHeader);
