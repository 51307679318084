import WarpElement from "@warp-ds/elements-core";
import { getPodletMetaData, setupCustomElement } from "../../lit-util.js";
import { html } from "lit";
import { PodletMetaData } from "../../../types/types-drEdition.js";
import { events, messageBus } from "@schibsted-nmp/advertising-events";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

const NAME = "contentful-sov-fetcher";

class ContentfulSovFetcher extends WarpElement {
	metaData!: PodletMetaData;
	ssrHtml!: string | null;

	static properties = {
		adPosition: { type: String },
	};

	constructor() {
		super();

		this.metaData = getPodletMetaData();

		if (this.metaData) {
			this.fetchContentMarketingData().then((contentMarketingRes) => {
				if (contentMarketingRes) {
					this.ssrHtml = contentMarketingRes;
					this.requestUpdate();
				} else {
					messageBus.publish(events.PODLET.channel, events.PODLET.CONTENT_MARKETING_NO_SOV.topic);
				}
			});
		}
	}

	async fetchContentMarketingData(): Promise<string | null> {
		const { baseUrl, vertical } = this.metaData;
		const fetchUrl = `${baseUrl}/api/advertising/contentful/html/teaser/sov?vertical=${vertical}`;
		try {
			const res = await fetch(fetchUrl).then((res) => {
				if (res.status === 200) {
					return res.json();
				} else {
					return null;
				}
			});
			return res?.html;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	render() {
		return html`${this.ssrHtml ? unsafeHTML(this.ssrHtml) : ""}`;
	}
}

setupCustomElement(NAME, ContentfulSovFetcher);
