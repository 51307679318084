// LitElement that is a paragraph block
import { css, html } from "lit";
import { setupCustomElement } from "../../lit-util.js";
import WarpElement from "@warp-ds/elements-core";

const NAME = "contentful-rich-text";

export class ContentfulRichText extends WarpElement {
	declare fadeout: boolean;
	constructor() {
		super();
		this.fadeout = false;
	}
	static properties = {
		fadeout: { type: Boolean },
	};

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			.fadeout {
				-webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
				mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
				overflow-y: hidden;
			}

			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static};
		`,
		...WarpElement.styles, // Adds the global styles
	] as unknown as never;

	render() {
		if (this.fadeout) return html`<div class="fadeout"><slot></slot></div>`;
		return html`<slot></slot>`;
	}
}

setupCustomElement(NAME, ContentfulRichText);
