// LitElement that is a paragraph block
import { css, html } from "lit";
import { setupCustomElement } from "../../lit-util.js";
import WarpElement from "@warp-ds/elements-core";

const NAME = "contentful-heading";

type HeadingType = "heading-1" | "heading-2" | "heading-3" | "heading-4" | "heading-5" | "heading-6" | "";
export class ContentfulHeading extends WarpElement {
	// define heading property
	declare heading: HeadingType;

	static properties = {
		heading: { type: String },
	};

	constructor() {
		super();
		this.heading = "";
	}

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.static{position:static};
		`,
		...WarpElement.styles, // Adds the global styles
	] as unknown as never;

	render() {
		switch (this.heading) {
			case "heading-1":
				return html`<h1 class="${this.className}"><slot></slot></h1>`;
			case "heading-2":
				return html`<h2 class="${this.className}"><slot></slot></h2>`;
			case "heading-3":
				return html`<h3 class="${this.className}"><slot></slot></h3>`;
			case "heading-4":
				return html`<h4 class="${this.className}"><slot></slot></h4>`;
			case "heading-5":
				return html`<h5 class="${this.className}"><slot></slot></h5>`;
			case "heading-6":
				return html`<h6 class="${this.className}"><slot></slot></h6>`;
			default:
				return html`<h1 class="${this.className}"><slot></slot></h1>`;
		}
	}
}

setupCustomElement(NAME, ContentfulHeading);
