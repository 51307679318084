import { css, html } from "lit";
import { getPodletMetaData, setupCustomElement } from "../../lit-util.js";
import WarpElement from "@warp-ds/elements-core";
import "@warp-ds/elements/components/button";
import { PodletMetaData } from "../../../types/types-drEdition.js";
import { logCreativeClick, logCreativeLoad, logCreativeView } from "../../tracking/sponsored-content-tracking.js";
import { ContentMarketingEntry, ContentMarketingFields } from "../../../types/types-contentful.js";
import { renderRichText } from "../utils/contentful-rich-text-render-switch.js";
import { CurrentPlatform, getCurrentPlatform } from "../utils/getCurrentPlatform.js";

const NAME = "contentful-teaser-wrapper";

export class ContentfulTeaserWrapper extends WarpElement {
	declare creativeId: string;
	declare entryId: string;
	declare metaData: PodletMetaData;
	declare contentModel: ContentMarketingEntry;
	declare currentPlatform: CurrentPlatform;
	declare adPosition: string;

	constructor() {
		super();
		this.creativeId = "";
		this.entryId = "";
		this.metaData = getPodletMetaData();
		this.contentModel = {} as ContentMarketingEntry;
		this.currentPlatform = {} as CurrentPlatform;

		getCurrentPlatform(this, (currentPlatform) => {
			this.currentPlatform = currentPlatform;
			this.requestUpdate(); // Make sure to update after font-size change

			document.addEventListener("DOMContentLoaded", () => {
				const html = document.querySelector("html");

				if (html) {
					// iOS requires upscaling of sizes
					if (this.currentPlatform.platform === "ios" && this.metaData.isWebView) {
						html.style.fontSize = "100%";
					} else {
						html.style.fontSize = "62.5%";
					}
				}

				this.requestUpdate(); // Make sure to update after font-size change
			});
		});
	}

	static properties = {
		id: { type: String },
		entryId: { type: String },
		creativeId: { type: String },
		contentModel: { type: Object },
		isMobile: { type: Boolean },
	};

	static styles = [
		css`
			* {
				font-family: "Finntype", sans-serif;
			}

			:host > article {
				padding: 0;
				color: #333;
				min-height: 20rem;
				overflow: hidden;
				transition:
					background-color 0.3s ease,
					color 0.3s ease;
			}

			button {
				transition: border-color 0.3s ease;
			}

			:host article:hover button {
				border-color: black;
			}

			:host > article.dark-mode {
				background-color: rgb(43, 43, 48);
				color: white;
			}

			:host > article.dark-mode * {
				color: white;
			}

			:host article.dark-mode #cta-button {
				background-color: rgb(26, 26, 30);
				color: white;
				border-color: white;
			}

			/** The line below is where the injected CSS goes, removing it means you get no CSS from the design system **/
			*,:before,:after{--w-rotate:0;--w-rotate-x:0;--w-rotate-y:0;--w-rotate-z:0;--w-scale-x:1;--w-scale-y:1;--w-scale-z:1;--w-skew-x:0;--w-skew-y:0;--w-translate-x:0;--w-translate-y:0;--w-translate-z:0}.border-solid{border-style:solid}.opacity-0{opacity:0}.static{position:static}.hover\:s-bg-hover:hover{background-color:var(--w-s-color-background-hover)}.hover\:s-border-primary-hover:hover{border-color:var(--w-s-color-border-primary-hover)}.my-8{margin-top:.8rem;margin-bottom:.8rem}.mt-8{margin-top:.8rem}.py-16{padding-top:1.6rem;padding-bottom:1.6rem}.transition{transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter,backdrop-filter;transition-duration:.15s;transition-timing-function:cubic-bezier(.4,0,.2,1)}.text-16{font-size:var(--w-font-size-m);line-height:var(--w-line-height-m)}.text-l{font-size:var(--w-font-size-l);line-height:var(--w-line-height-l)};
		`,
		...WarpElement.styles, // Adds the global styles
	] as unknown as never;

	getAdPositionFromParentComponent() {
		// Get this dynamically instead
		return this.currentPlatform.isMobile ? "board_2" : "netboard_2";
	}

	firstUpdated() {
		if (!this.metaData.isWebView) {
			this.adPosition = this.getAdPositionFromParentComponent();
			this.initialTracking();
		}
	}

	initialTracking() {
		this.trackLoad();
		this.setupTrackView();
	}

	setupTrackView() {
		const target = this.shadowRoot?.getElementById(this.creativeId);
		if (target) {
			let viewTimeout: NodeJS.Timeout | null = null; // Timer for tracking view

			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							// Start timer when the element is in view
							viewTimeout = setTimeout(() => {
								this.trackView();
								observer.disconnect();
							}, 1000);
						} else if (viewTimeout) {
							// Clear the timer if the element is out of view
							clearTimeout(viewTimeout);
							viewTimeout = null;
						}
					});
				},
				{
					threshold: 0.5,
				},
			);
			observer.observe(target);
		}
	}

	trackLoad() {
		logCreativeLoad({
			adCreative: this.creativeId,
			adPosition: this.adPosition,
			verticalName: this.metaData?.vertical || "",
			platform: this.currentPlatform.platform,
			provider: this.contentModel.fields.advertiserName,
		});
	}

	trackClick() {
		const fields = this.contentModel.fields as ContentMarketingFields;
		logCreativeClick({
			adCreative: this.creativeId,
			adPosition: this.adPosition,
			verticalName: this.metaData?.vertical || "",
			platform: this.currentPlatform.platform,
			targetId: fields.url || "",
			provider: fields.advertiserName || "",
		});
	}

	trackView() {
		logCreativeView({
			adCreative: this.creativeId,
			adPosition: this.adPosition,
			verticalName: this.metaData?.vertical || "",
			platform: this.currentPlatform.platform,
			provider: this.contentModel.fields.advertiserName,
		});
	}

	getImageBasedOnDevice() {
		const { mobileMainImage, mainImage } = this.contentModel.fields;
		if (this.currentPlatform.isMobile && mobileMainImage && mobileMainImage.fields) {
			return {
				data: mobileMainImage.fields,
				fieldId: "mobileMainImage",
			};
		}
		return {
			data: mainImage.fields,
			fieldId: "mainImage",
		};
	}

	render() {
		const { contentModel, metaData } = this;
		const fields = contentModel?.fields as ContentMarketingFields;
		if (!fields) return html``;
		const { url, kicker, title, tagline, ctaButtonText } = fields;
		const imageField = this.getImageBasedOnDevice();
		return html`
			<article
				class="shadow-m relative rounded-8 rounded-8 ${metaData.darkMode ? "dark-mode" : "s-bg hover:s-bg-hover"} "
				id="${this.creativeId}"
			>
				<contentful-image
					.imageData="${imageField.data}"
					.aspectRatio="${true}"
					landscapeMode="${metaData.landscapeMode}"
					imageStyle="width: 100%"
					entryId="${this.entryId}"
					fieldId="${imageField.fieldId}"
				></contentful-image>
				<div class="${this.currentPlatform.platform === "android" ? "px-8 py-16" : "p-16"} card">
					${kicker
						? html`<contentful-paragraph class="font-bold text-m mb-0 mt-8" entryId="${this.entryId}" fieldId="kicker"
								>${kicker}</contentful-paragraph
							>`
						: ""}
					<contentful-heading heading="heading-2" class="font-normal text-l" .entryId="${this.entryId}" .fieldId="title"
						>${title}</contentful-heading
					>
					${tagline
						? html`<contentful-rich-text
								fadeout="true"
								data-contentful-entry-id="${this.entryId}"
								data-contentful-field-id="tagline"
								>${renderRichText(tagline)}</contentful-rich-text
							>`
						: ""}
					${ctaButtonText
						? html`<button
								id="cta-button"
								class="my-8 inline-block px-16 py-8 rounded-8 border border-solid text-16 ${metaData.darkMode
									? ""
									: "s-bg s-border hover:s-border-primary-hover"} "
								data-contentful-entry-id="${this.entryId}"
								data-contentful-field-id="ctaButtonText"
							>
								${ctaButtonText}
							</button>`
						: ""}
				</div>
				<slot></slot>
				${url
					? html`<a
							target="_blank"
							rel="noreferrer"
							@click="${this.trackClick}"
							class="inset-0 absolute opacity-0"
							href="${url}"
							data-contentful-entry-id="${this.entryId}"
							data-contentful-field-id="url"
							>${kicker} ${title}</a
						>`
					: ""}
			</article>
		`;
	}
}

setupCustomElement(NAME, ContentfulTeaserWrapper);
